import React, { useState, useEffect } from "react";
import { FormProps } from "./FormStretch";
import { Typography, Grid } from "@material-ui/core";
import InputForm from "../Forms/Input/InputForm";
import TemperatureSlider from "../Forms/Slider/TemperatureSlider";
import SelectForm from "../Forms/Select/SelectForm";
import { frequencyOptionsYearly } from "./stretchModels";
import { formatCurrency } from "../../lib/utils";
import QuestionMarkButton from "./QuestionMarkButton";
import styles from "./FormStretch.module.scss";
import stretchStyles from "./MoneyStretch.module.scss";
import clsx from "clsx";
interface MoneyInProps extends FormProps {
  currencyProps?: any;
  normalInput?: any;
}

const MoneyIn: React.FC<MoneyInProps> = ({
  form,
  handleFormChange,
  currencyProps,
  getTotalPercentageVal1,
  getTotalPercentageVal2,
  handleFormSlider1Change,
  handleFormSlider2Change,
  getTotalMoneyIn,
  currentSpendingCount,
  baselineSpendingCount,
  essentialSpendingCount,
  normalInput,
}) => {
  const [showInfoMessage, setShowInfoMessage] = useState(false);

  return (
    <>
      <Typography variant="h5" className={styles.title}>
        Money In
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={10}>
          <Grid container>
            <Grid></Grid>
            <Grid item xs={3} className={styles.inputContainer}>
              <Typography align="center" variant="h5" className={styles.title}>
                Person 1
              </Typography>
            </Grid>
            <Grid item xs={3} className={styles.inputContainer}>
              <Typography align="center" variant="h5" className={styles.title}>
                Person 2
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} md={10}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={2}>
              <Typography variant="subtitle1" className={styles.paragraphTitle}>
                <b> Total Net Income </b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                name="totalNetIncomeClient1"
                value={form.totalNetIncomeClient1}
                onChange={handleFormChange}
                inputProps={currencyProps}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                type="number"
                name="totalNetIncomeClient2"
                value={form.totalNetIncomeClient2}
                onChange={handleFormChange}
                inputProps={currencyProps}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <SelectForm
                value={form.netIncomeFrequency}
                options={frequencyOptionsYearly}
                onChange={handleFormChange}
                id="netIncomeFrequency"
                name="netIncomeFrequency"
              />
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <Grid container alignItems="center">
                <InputForm
                  type="text"
                  name="totalNetIncomeClient1PerCentage"
                  value={`${form.totalNetIncomeClient1Percentage}%`}
                  disabled
                  inputClass={styles.input}
                />
                <Typography
                  display="block"
                  variant="subtitle2"
                  className={clsx(styles.paragraph, styles.percentageVal)}
                >
                  <b>{formatCurrency(getTotalPercentageVal1())} </b>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <Grid container alignItems="center">
                <InputForm
                  type="text"
                  name="totalNetIncomeClient2PerCentage"
                  value={`${form.totalNetIncomeClient2Percentage}%`}
                  disabled
                  inputClass={styles.input2}
                />
                <Typography
                  display="block"
                  variant="subtitle2"
                  className={clsx(styles.paragraph, styles.percentageVal)}
                >
                  <b>{formatCurrency(getTotalPercentageVal2())} </b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={3} className={styles.inputContainer}>
              <TemperatureSlider
                name="totalNetIncomeClient1PerCentage"
                onChange={handleFormSlider1Change}
                value={form.totalNetIncomeClient1Percentage}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <TemperatureSlider
                name="totalNetIncomeClient2PerCentage"
                onChange={handleFormSlider2Change}
                value={form.totalNetIncomeClient2Percentage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={12} md={2}>
          <Typography variant="subtitle1" className={styles.paragraphTitle}>
            <b> Total Money In </b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                disabled
                type="number"
                name="totalMoneyIn"
                value={getTotalMoneyIn()}
                inputProps={currencyProps}
                inputClass={styles.inputDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}>
              <InputForm
                fullWidth
                disabled
                type="text"
                name="netIncomeFrequency"
                value={"Monthly"}
                inputClass={styles.inputDisabled}
                inputProps={normalInput}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={styles.inputContainer}></Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={styles.noteContainer}>
        <h6 className={styles.title}>
          Insights
          <QuestionMarkButton
            cssClass={stretchStyles.questionIconGraph}
            onClick={() => setShowInfoMessage(!showInfoMessage)}
          />
          {showInfoMessage ? (
            <div
              className={clsx(
                styles.messageBlock,
                styles.questionBeside,
                styles.fixedWidth
              )}
            >
              <div>
                <Typography variant="h6" className={styles.paragraphTitle}>
                  <ul>
                    <li>
                      <b>No Change</b> – This is a static line that shows how
                      your cash buffers arecurrently performing based on the
                      data you imported from your Live Financial Position. Any
                      changes made below will not be reflected here.
                    </li>
                    <li>
                      <b>Baseline Money Available</b> – This shows you how your
                      cash buffers are currently performing based on your
                      current Money In and current Spending. Any changes to your
                      essential and discretionary spending will be reflected
                      here.
                    </li>
                    <li>
                      <b>Money Available with Essential Spending</b> - This
                      shows you how your cash buffers will perform with the
                      specified Money In values and if you were to cut out
                      Discretionary Spending. Any changes to your Money In and
                      essential spending will be reflected here.
                    </li>
                    <li>
                      <b>Money Available with Current Spending</b> – This shows
                      you how your cash buffers will perform with the specified
                      Money In values and your current spending habits and
                      arrangements. Any changes to your Money In, essential and
                      discretionary spending will be reflected here.
                    </li>
                  </ul>
                </Typography>
              </div>
            </div>
          ) : null}
        </h6>
        <Typography variant="subtitle1" className={styles.paragraph}>
          Your baseline scenario sourced from your live data in moorr indicates{" "}
          {baselineSpendingCount > 36
            ? `you have more than `
            : baselineSpendingCount === 1
            ? `you have `
            : `you have `}
          <b className={styles.primary}>
            {baselineSpendingCount > 36
              ? `36 Months `
              : baselineSpendingCount === 1
              ? `${baselineSpendingCount} Month `
              : `${baselineSpendingCount} Months `}
          </b>
          of money reserves.
          <br />
          The changes you have made show the following scenarios:
          <br />- Your current spending scenario shows your money lasting
          <b className={styles.primary}>
            {currentSpendingCount > 36
              ? ` more than 36 Months `
              : ` ${currentSpendingCount} ${
                  currentSpendingCount === 1 ? "Month" : "Months"
                } `}
          </b>
          <br />- Your essential spending scenario shows your money lasting
          <b className={styles.primary}>
            {essentialSpendingCount > 36
              ? ` more than 36 Months `
              : ` ${essentialSpendingCount} ${
                  essentialSpendingCount === 1 ? "Month" : "Months"
                } `}
          </b>
        </Typography>
      </div>
    </>
  );
};

export default React.memo(MoneyIn);
