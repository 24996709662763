import React, { useContext, useState, useEffect } from "react";
import HeaderStretch from "./HeaderStretch";
import GraphStretch from "./GraphStretch";
import FormStretch from "./FormStretch";
import AppDataContext from "../../context/AppDataContext";
import MoneyStretchContext from "../../context/MoneyStretchContext";
import { intialValues } from "./stretchModels";
import { toast } from "react-toastify";
import {
  withStyles,
  StyleRules,
  WithStyles,
  Theme,
} from "@material-ui/core/styles";
import { addMoneyStretch, publishStretchChanges } from "../../lib/services";
import _ from "lodash";
import {
  getMonthlyFrequencyMultiplier,
  getAllBills,
  getAllSpendings,
} from "../../lib/utils";
import styles from "./MoneyStretch.module.scss";

const MoneyStretch: React.FC = () => {
  const { summary, newDashboardValues, moneyStretch } =
    useContext(AppDataContext);

  const [importLiveData, setImportLiveData] = useState<boolean>(false);
  const [form, setForm] = useState(intialValues);
  const [currentSpendingCount, setCurrentSpendingCount] = useState(1);
  const [baselineSpendingCount, setBaselineSpendingCount] = useState(1);
  const [essentialSpendingCount, setEssentialSpendingCount] = useState(1);
  const [isPublishDisable, setIsPublishDisable] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const getBillsFromObj = (obj: any[]) => {
    const filteredBills = _.filter(obj, (val) => {
      return getAllBills(val);
    });
    const filteredCustomBills = _.filter(obj, (val) => {
      if (val.tier4 === "Bills" && val.customExpense === true) return val;
    });

    const allBills = [...filteredBills, ...filteredCustomBills];
    const completeData = allBills.map((expense) => {
      const newObj = {
        amount: expense.amount ?? 0,
        basic: expense.basic ?? 0,
        desc: expense.desc,
        category: expense.category ?? "7 Days Float",
        customExpense: expense.customExpense ?? false,
        discretionary: expense.discretionary ?? 0,
        frequency:
          expense.frequency === null || expense.frequency === ""
            ? "Monthly"
            : expense.frequency,
        taxDeductible: expense.taxDeductible ?? false,
        tier1: expense.tier1,
        tier2: expense.tier2,
        tier3: expense.tier3,
        tier4: expense.tier4,
        _id: expense._id,
      };
      return newObj;
    });
    return completeData;
  };

  const getSpendingFromObj = (obj: any) => {
    const filteredSpending = _.filter(obj, (val) => {
      return getAllSpendings(val);
    });

    const filteredCustomSpending = _.filter(obj, (val) => {
      if (val.tier4 === "Spending" && val.customExpense === true) return val;
    });
    const allSpending = [...filteredSpending, ...filteredCustomSpending];

    const completeData = allSpending.map((expense) => {
      const newObj = {
        amount: expense.amount ?? 0,
        basic: expense.basic ?? 0,
        category: expense.category ?? "7 Days Float",
        customExpense: expense.customExpense ?? false,
        discretionary: expense.discretionary ?? 0,
        desc: expense.desc,
        frequency:
          expense.frequency === null || expense.frequency === ""
            ? "Monthly"
            : expense.frequency,
        taxDeductible: expense.taxDeductible ?? false,
        tier1: expense.tier1,
        tier2: expense.tier2,
        tier3: expense.tier3,
        tier4: expense.tier4,
        _id: expense._id,
      };
      return newObj;
    });
    return completeData;
  };

  const cashInBankSummary = _.get(summary, "assets.bankAccounts", [])
    .map((bank) => {
      const balance = _.get(bank, "balance", 0);
      return balance;
    })
    .reduce((prev, curr) => {
      return prev + curr;
    }, 0);

  const liveData = {
    staticBaseLine: newDashboardValues.staticBaseLine ?? [],
    totalNetIncomeClient1: newDashboardValues.totalNetIncomeClient1 ?? 0,
    totalNetIncomeClient2: newDashboardValues.totalNetIncomeClient2 ?? 0,
    totalMonthlyBillsEssential: summary.expenses.annualBills.basic / 12 ?? 0,
    totalMonthlySpendingEssential:
      summary.expenses.annualSpendings.basic / 12 ?? 0,
    totalNetIncomeClient1Percentage: 100,
    totalNetIncomeClient2Percentage: 100,
    netIncomeFrequency: "Yearly",
    cashInBank: cashInBankSummary ?? 0,
    otherAvailableFunds: 0,
    totalLoanRepayments: newDashboardValues.totalLoanPayments[0] ?? 0,
    totalLoanRepaymentsFrequency: "Monthly",
    investmentPropertyCostFrequency: "Monthly",
    investmentPropertyCost:
      newDashboardValues.totalInvestmentPropertyCosts[0] ?? 0,
    otherPropertyCostFrequency: "Monthly",
    otherPropertyCost: newDashboardValues.totalOtherPropertyCosts[0] ?? 0,
    investmentAssetCost: newDashboardValues.totalInvestmentAssetCosts[0] ?? 0,
    investmentAssetCostFrequency: "Monthly",
    contributionsOtherInvestments:
      newDashboardValues.totalContributionsOtherInvestments[0] ?? 0,
    contributionsOtherInvestmentsFrequency: "Monthly",
    afterTaxSuperContributions:
      newDashboardValues.afterTaxSuperContributions[0] ?? 0,
    afterTaxSuperContributionsFrequency: "Monthly",
    bills: getBillsFromObj(summary.expenses.expenses),
    spending: getSpendingFromObj(summary.expenses.expenses),
  };

  const stretchData = moneyStretch
    ? {
        staticBaseLine: moneyStretch.staticBaseLine ?? [],
        totalNetIncomeClient1: moneyStretch.totalNetIncomeClient1 ?? 0,
        totalNetIncomeClient2: moneyStretch.totalNetIncomeClient2 ?? 0,
        totalNetIncomeClient1Percentage:
          moneyStretch.totalNetIncomeClient1Percentage ?? 0,
        totalMonthlyBillsEssential:
          moneyStretch.totalMonthlyBillsEssential ?? 0,
        totalMonthlySpendingEssential:
          moneyStretch.totalMonthlySpendingEssential ?? 0,
        totalNetIncomeClient2Percentage:
          moneyStretch.totalNetIncomeClient2Percentage ?? 0,
        netIncomeFrequency: moneyStretch.netIncomeFrequency ?? "Yearly",
        cashInBank: moneyStretch.cashInBank ?? 0,
        otherAvailableFunds: moneyStretch.otherAvailableFunds ?? 0,
        totalLoanRepayments: moneyStretch.totalLoanRepayments ?? 0,
        totalLoanRepaymentsFrequency:
          moneyStretch.totalLoanRepaymentsFrequency ?? "Monthly",
        investmentPropertyCost: moneyStretch.investmentPropertyCost ?? 0,
        investmentPropertyCostFrequency:
          moneyStretch.investmentPropertyCostFrequency ?? "Monthly",
        investmentAssetCost: moneyStretch.investmentAssetCost ?? 0,
        investmentAssetCostFrequency:
          moneyStretch.investmentAssetCostFrequency ?? "Monthly",
        contributionsOtherInvestments:
          moneyStretch.contributionsOtherInvestments ?? 0,
        contributionsOtherInvestmentsFrequency:
          moneyStretch.contributionsOtherInvestmentsFrequency ?? "Monthly",
        afterTaxSuperContributions:
          moneyStretch.afterTaxSuperContributions ?? 0,
        afterTaxSuperContributionsFrequency:
          moneyStretch.afterTaxSuperContributionsFrequency ?? "Monthly",
        otherPropertyCost: moneyStretch.otherPropertyCost ?? 0,
        otherPropertyCostFrequency:
          moneyStretch.otherPropertyCostFrequency ?? "Monthly",
        bills: moneyStretch.bills,
        spending: moneyStretch.spending,
      }
    : null;

  useEffect(() => {
    if (form.bills && liveData.bills && form.spending && liveData.spending) {
      const stretchBills = _.cloneDeep(liveData.bills);
      const stretchSpending = _.cloneDeep(liveData.spending);
      const isEqualBills = _.isEqual(stretchBills, form.bills);
      const isEqualSpending = _.isEqual(stretchSpending, form.spending);

      if (isEqualBills && isEqualSpending) setIsPublishDisable(true);
      else setIsPublishDisable(false);
    }
  }, [form.bills, form.spending, liveData]);

  useEffect(() => {
    if (moneyStretch && !importLiveData) setForm(stretchData);
    if (importLiveData) setForm(liveData);
    if (!moneyStretch) setForm(liveData);
  }, [importLiveData]);

  const handleFormChange: (event: any, index: any) => void = (
    event: any,
    index: any
  ) => {
    const toBeModified = event.target.value;
    const modified = toBeModified.replace(/,/g, "");

    switch (event.target.name) {
      case "netIncomeFrequency":
        setForm({ ...form, netIncomeFrequency: modified });
        break;
      case "totalNetIncomeClient1":
        setForm({ ...form, totalNetIncomeClient1: Number(modified) });
        break;
      case "totalNetIncomeClient2":
        setForm({ ...form, totalNetIncomeClient2: Number(modified) });
        break;
      case "cashInBank":
        setForm({ ...form, cashInBank: Number(modified) });
        break;
      case "otherAvailableFunds":
        setForm({ ...form, otherAvailableFunds: Number(modified) });
        break;
      case "totalLoanRepayments":
        setForm({ ...form, totalLoanRepayments: Number(modified) });
        break;
      case "investmentPropertyCost":
        setForm({
          ...form,
          investmentPropertyCost: Number(modified),
        });
        break;
      case "otherPropertyCost":
        setForm({
          ...form,
          otherPropertyCost: Number(modified),
        });
        break;
      case "investmentAssetCost":
        setForm({
          ...form,
          investmentAssetCost: Number(modified),
        });
        break;
      case "contributionsOtherInvestments":
        setForm({
          ...form,
          contributionsOtherInvestments: Number(modified),
        });
        break;
      case "afterTaxSuperContributions":
        setForm({
          ...form,
          afterTaxSuperContributions: Number(modified),
        });
        break;
      case "totalLoanRepaymentsFrequency":
        setForm({ ...form, totalLoanRepaymentsFrequency: modified });
        break;
      case "investmentPropertyCostFrequency":
        setForm({
          ...form,
          investmentPropertyCostFrequency: modified,
        });
        break;
      case "otherPropertyCostFrequency":
        setForm({
          ...form,
          otherPropertyCostFrequency: modified,
        });
        break;
      case "investmentAssetCostFrequency":
        setForm({
          ...form,
          investmentAssetCostFrequency: modified,
        });
        break;
      case "contributionsOtherInvestmentsFrequency":
        setForm({
          ...form,
          contributionsOtherInvestmentsFrequency: modified,
        });
        break;
      case "afterTaxSuperContributionsFrequency":
        setForm({
          ...form,
          afterTaxSuperContributionsFrequency: modified,
        });
        break;
      default:
        return;
    }
  };

  const handleFormChangeFrequencyBills: (event: any, index: number) => void = (
    event: any,
    index: number
  ) => {
    let newArr = [...form.bills];
    newArr[index] = {
      ...form.bills[index],
      frequency: event.target.value,
    };
    const totalEssentialBills = newEssentialBills(newArr) ?? 0;
    setForm({
      ...form,
      bills: newArr,
      totalMonthlyBillsEssential: totalEssentialBills,
    });
  };

  const handleFormChangeFrequencySpending: (event: any, index: number) => void =
    (event: any, index: number) => {
      let newArr = [...form.spending];
      newArr[index] = {
        ...form.spending[index],
        frequency: event.target.value,
      };
      const totalSpendingEssential = newEssentialSpending(newArr) ?? 0;
      setForm({
        ...form,
        spending: newArr,
        totalMonthlySpendingEssential: totalSpendingEssential,
      });
    };

  const handleFormChangeBillsBasic: (event: any, index: number) => void = (
    event: any,
    index: number
  ) => {
    const toBeModified = event.target.value;
    const modified = toBeModified.replace(/,/g, "");
    let newArr = [...form.bills];
    newArr[index] = {
      ...form.bills[index],
      basic: Number(modified),
    };

    const totalEssentialBills = newEssentialBills(newArr) ?? 0;
    setForm({
      ...form,
      bills: newArr,
      totalMonthlyBillsEssential: totalEssentialBills,
    });
  };

  const handleFormChangeBillsDiscretionary: (
    event: any,
    index: number
  ) => void = (event: any, index: number) => {
    const toBeModified = event.target.value;
    const modified = toBeModified.replace(/,/g, "");
    let newArr = [...form.bills];
    newArr[index] = {
      ...form.bills[index],
      discretionary: Number(modified),
    };
    const totalEssentialBills = newEssentialBills(newArr) ?? 0;
    setForm({
      ...form,
      bills: newArr,
      totalMonthlyBillsEssential: totalEssentialBills,
    });
  };

  const handleFormChangeSpendingBasic: (event: any, index: number) => void = (
    event: any,
    index: number
  ) => {
    const toBeModified = event.target.value;
    const modified = toBeModified.replace(/,/g, "");
    let newArr = [...form.spending];
    newArr[index] = {
      ...form.spending[index],
      basic: Number(modified),
    };
    const totalSpendingEssential = newEssentialSpending(newArr) ?? 0;
    setForm({
      ...form,
      spending: newArr,
      totalMonthlySpendingEssential: totalSpendingEssential,
    });
  };

  const handleFormChangeSpendingDiscretionary: (
    event: any,
    index: number
  ) => void = (event: any, index: number) => {
    const toBeModified = event.target.value;
    const modified = toBeModified.replace(/,/g, "");
    let newArr = [...form.spending];
    newArr[index] = {
      ...form.spending[index],
      discretionary: Number(modified),
    };
    const totalSpendingEssential = newEssentialSpending(newArr) ?? 0;
    setForm({
      ...form,
      spending: newArr,
      totalMonthlySpendingEssential: totalSpendingEssential,
    });
  };

  const handleImportLiveData = async () => {
    setImportLiveData(true);
    toast.success("Successfully imported your data from the client portal", {
      autoClose: 5000,
      closeOnClick: true,
      hideProgressBar: true,
      pauseOnHover: true,
      position: "top-right",
    });
  };
  const getTotalPercentageVal1 = () => {
    return (
      form.totalNetIncomeClient1 * (form.totalNetIncomeClient1Percentage / 100)
    );
  };

  const getTotalPercentageVal2 = () => {
    return (
      form.totalNetIncomeClient2 * (form.totalNetIncomeClient2Percentage / 100)
    );
  };

  const getTotalMoneyAvailable = () => {
    return form.cashInBank + form.otherAvailableFunds;
  };

  const handleFormSlider1Change = (event, v) => {
    setForm({ ...form, totalNetIncomeClient1Percentage: parseInt(v) });
  };

  const handleFormSlider2Change = (event, v) => {
    setForm({ ...form, totalNetIncomeClient2Percentage: parseInt(v) });
  };

  const newEssentialSpending = (spending) => {
    const temp = _.sumBy(spending, function (val: any) {
      if (val.basic && Number(val.basic))
        return val.basic * getMonthlyFrequencyMultiplier(val.frequency);
    });
    return temp ? temp : 0;
  };

  const sumEssentailSpending = () => {
    const temp = _.sumBy(form.spending, function (val: any) {
      if (val.basic && Number(val.basic))
        return val.basic * getMonthlyFrequencyMultiplier(val.frequency);
    });
    return temp ? temp : 0;
  };
  const sumDiscretionarySpending = () => {
    const temp = _.sumBy(form.spending, function (val: any) {
      if (val.discretionary && Number(val.discretionary))
        return val.discretionary * getMonthlyFrequencyMultiplier(val.frequency);
    });
    return temp ? temp : 0;
  };

  const sumEssentailBills = () => {
    const temp =
      _.sumBy(form.bills, function (val: any) {
        if (val.basic && Number(val.basic)) {
          return val.basic * getMonthlyFrequencyMultiplier(val.frequency);
        }
      }) ?? 0;
    return temp;
  };
  const newEssentialBills = (bills) => {
    const temp =
      _.sumBy(bills, function (val: any) {
        if (val.basic && Number(val.basic)) {
          return val.basic * getMonthlyFrequencyMultiplier(val.frequency);
        }
      }) ?? 0;
    return temp;
  };
  const sumDiscretionaryBills = () => {
    const temp = _.sumBy(form.bills, function (val: any) {
      if (val.discretionary && Number(val.discretionary))
        return val.discretionary * getMonthlyFrequencyMultiplier(val.frequency);
    });
    return temp ? temp : 0;
  };

  const sumEssential = () => {
    return sumEssentailSpending() + sumEssentailBills();
  };

  const sumDiscretionary = () => {
    return sumDiscretionaryBills() + sumDiscretionarySpending();
  };

  const getTotalMoneyIn = () => {
    return (
      (getTotalPercentageVal1() + getTotalPercentageVal2()) *
      getMonthlyFrequencyMultiplier(form.netIncomeFrequency as any)
    );
  };

  const getAllTotalMoneyIn = () => {
    return (
      (form.totalNetIncomeClient2 + form.totalNetIncomeClient1) *
      getMonthlyFrequencyMultiplier(form.netIncomeFrequency as any)
    );
  };

  const getTotalMoneyOutEssential = () => {
    return (
      form.totalMonthlyBillsEssential +
      form.totalMonthlySpendingEssential +
      getTotalLoanRepayments() +
      getTotalInvestmentPropertyCost() +
      getTotalOtherPropertyCost() +
      getTotalInvestmentAssetCost() +
      getTotalContributionsOtherInvestments() +
      getTotalAfterTaxSuperContributions()
    );
  };

  const getTotalMoneyOutDiscretionary = () => {
    return sumDiscretionary();
  };

  const getTotalMoneyOut = () => {
    return getTotalMoneyOutEssential() + getTotalMoneyOutDiscretionary();
  };

  const getTotalSurplusDeficit = () => {
    return (
      getTotalMoneyIn() -
      (getTotalMoneyOutEssential() + getTotalMoneyOutDiscretionary())
    );
  };

  const getTotalLoanRepayments = () => {
    return (
      form.totalLoanRepayments *
      getMonthlyFrequencyMultiplier(form.totalLoanRepaymentsFrequency as any)
    );
  };

  const getTotalInvestmentPropertyCost = () => {
    return (
      form.investmentPropertyCost *
      getMonthlyFrequencyMultiplier(form.investmentPropertyCostFrequency as any)
    );
  };

  const getTotalOtherPropertyCost = () => {
    return (
      form.otherPropertyCost *
      getMonthlyFrequencyMultiplier(form.otherPropertyCostFrequency as any)
    );
  };

  const getTotalInvestmentAssetCost = () => {
    return (
      form.investmentAssetCost *
      getMonthlyFrequencyMultiplier(form.investmentAssetCostFrequency as any)
    );
  };

  const getTotalContributionsOtherInvestments = () => {
    return (
      form.contributionsOtherInvestments *
      getMonthlyFrequencyMultiplier(
        form.contributionsOtherInvestmentsFrequency as any
      )
    );
  };

  const getTotalAfterTaxSuperContributions = () => {
    return (
      form.afterTaxSuperContributions *
      getMonthlyFrequencyMultiplier(
        form.afterTaxSuperContributionsFrequency as any
      )
    );
  };

  const handleSaveChanges = async () => {
    try {
      const res = await addMoneyStretch(form);
      toast.success("Successfully saved your data to moneySTRETCH", {
        autoClose: 5000,
        closeOnClick: true,
        hideProgressBar: true,
        pauseOnHover: true,
        position: "top-right",
      });
    } catch (err) {
      console.log(err);
      toast.error("Oops something went wrong!", {
        autoClose: 5000,
        closeOnClick: true,
        hideProgressBar: true,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };

  const handlePublishChanges = async () => {
    try {
      const res = await publishStretchChanges({
        bills: form.bills,
        spending: form.spending,
      });
      toast.success(
        "Successfully published your expenses changes to the client portal",
        {
          autoClose: 5000,
          closeOnClick: true,
          hideProgressBar: true,
          pauseOnHover: true,
          position: "top-right",
        }
      );
    } catch (err) {
      console.log(err);
      toast.error("Oops something went wrong!", {
        autoClose: 5000,
        closeOnClick: true,
        hideProgressBar: true,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  };

  return (
    <MoneyStretchContext.Provider value={{ setShowMessage, showMessage }}>
      <div className={styles.moneyStretchRoot}>
        <div>
          <HeaderStretch />
          <GraphStretch
            form={form}
            {...{
              getTotalMoneyIn,
              handleImportLiveData,
              getTotalMoneyAvailable,
              getAllTotalMoneyIn,
              getTotalMoneyOut,
              getTotalMoneyOutEssential,
              setCurrentSpendingCount,
              setBaselineSpendingCount,
              setEssentialSpendingCount,
            }}
          />
          <FormStretch
            handleFormChange={handleFormChange}
            handleFormSlider1Change={handleFormSlider1Change}
            handleFormSlider2Change={handleFormSlider2Change}
            handleSaveChanges={handleSaveChanges}
            handlePublishChanges={handlePublishChanges}
            handleFormChangeBillsDiscretionary={
              handleFormChangeBillsDiscretionary
            }
            handleFormChangeBillsBasic={handleFormChangeBillsBasic}
            handleFormChangeSpendingBasic={handleFormChangeSpendingBasic}
            handleFormChangeSpendingDiscretionary={
              handleFormChangeSpendingDiscretionary
            }
            handleFormChangeFrequencyBills={handleFormChangeFrequencyBills}
            handleFormChangeFrequencySpending={
              handleFormChangeFrequencySpending
            }
            form={form}
            {...{
              getTotalMoneyIn,
              getTotalPercentageVal1,
              getTotalPercentageVal2,
              getTotalMoneyAvailable,
              sumDiscretionaryBills,
              sumDiscretionarySpending,
              sumEssentailSpending,
              sumEssentailBills,
              sumEssential,
              sumDiscretionary,
              getTotalSurplusDeficit,
              getTotalMoneyOutEssential,
              getTotalMoneyOutDiscretionary,
              currentSpendingCount,
              baselineSpendingCount,
              essentialSpendingCount,
              isPublishDisable,
            }}
          />
        </div>
      </div>
    </MoneyStretchContext.Provider>
  );
};

export default MoneyStretch;
