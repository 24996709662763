import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";

import styles from "./MoneyStretch.module.scss";

const HeaderStretch: React.FC = () => {
  return (
    <div>
      <h5 className={clsx(styles.title, styles.titleBold)}>
        MoneySTRETCH - How Long will Your Money Last You?
      </h5>
      <p className={styles.headingParagraph}>
        What would be the impact of earning less, spending more or less to your
        cash reserves?
      </p>
      <h6 className={styles.title}>Welcome to MoneySTRETCH </h6>
      <p>
        An interactive self-assessment tool designed to help you forecast
        cashflow and cash on hand impacts of future financial decisions that you
        may or may not be in control of. Are you expecting your Income or
        expenses to change? Do you want to see the impact? Are you thinking of
        taking a sabbatical? Planning to have a child? Want a career change? By
        using this tool, you gain invaluable insights, actions you may need to
        take and hopefully overall peace of mind when managing your money.
      </p>
      <h6 className={styles.title}>How it Works</h6>
      <p>
        <strong> Step 1 </strong> : Import your current financial position data,
        using the Import Live Data button. A copy of your live financial
        Position data has been created within this interactive sandpit, allowing
        you to input various changes to these figures and explore different
        scenarios that will impact your monthly cashflow and savings buffers
        (cash on hand).
      </p>
      <p>
        <strong> Step 2 </strong>: Start playing: You can adjust incomes, by way
        of example – reducing income when starting a family. You can adjust
        Essential and Discretionary Spending for individual expense items within
        the Bills and Spending titles. You can adjust Total Loan Repayments,
        Investment Property and or Other Property costs to gauge their impacts.
        Finally, if you want to make these adjusted figures your new Live
        Financial Position figures you can export via the publish changes
        button.
      </p>
      <h6 className={styles.title}>Opti tip</h6>
      <p>
        To get the most out of this money insights feature within Moorr - do
        your best to separate essential from discretionary expenses amounts
        within each expense item. This will showcase to you just how far your
        money can stretch, if you needed it to, if your current financial
        circumstances are about to change.
      </p>
    </div>
  );
};

export default HeaderStretch;
