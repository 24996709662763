import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { formatCurrency } from "../../lib/utils";
import QuestionMarkButton from "./QuestionMarkButton";
import styles from "./MoneyStretch.module.scss";
import Fstyles from "./FormStretch.module.scss";
import clsx from "clsx";
interface Props {
  form: any;
  handleImportLiveData?: () => any;
  getTotalMoneyAvailable?: () => number;
  getAllTotalMoneyIn?: () => number;
  getTotalMoneyOut?: () => number;
  getTotalMoneyIn?: () => number;
  getTotalMoneyOutEssential?: () => number;
  setCurrentSpendingCount?: (value: any) => any;
  setBaselineSpendingCount?: (value: any) => any;
  setEssentialSpendingCount?: (value: any) => any;
}

const GraphStretch: React.FC<Props> = ({
  form,
  handleImportLiveData,
  getTotalMoneyAvailable,
  getAllTotalMoneyIn,
  getTotalMoneyIn,
  getTotalMoneyOut,
  getTotalMoneyOutEssential,
  setCurrentSpendingCount,
  setBaselineSpendingCount,
  setEssentialSpendingCount,
}) => {
  const [baseLine, setBaseLine] = useState([]);
  const [staticBaseLine, setStaticBaseLine] = useState([]);
  const [essentialSpending, setEssentialSpending] = useState([]);
  const [currentSpending, setCurrentSpending] = useState([]);
  const [countMonths, setCurrentMonths] = useState(37);
  const [monthsLabel, setMonthsLabel] = useState([]);
  const [showInfoMessage, setShowInforMessage] = useState(false);

  useEffect(() => {
    getMonthList();
    getBaseLine();
    getCurrentSpending();
    getEssentialSpending();
  }, [form]);

  const getBaseLine = () => {
    var i = 0;
    var baseLine = [];

    while (countMonths >= i) {
      var allTotalMoneyInPerMonth = getAllTotalMoneyIn() * i;
      var moneyAvailable = getTotalMoneyAvailable();
      var expensesPerMonth = getTotalMoneyOut() * i;

      const baseLineValue =
        allTotalMoneyInPerMonth + moneyAvailable - expensesPerMonth;

      if (baseLineValue > 0) baseLine.push(baseLineValue);
      else break;

      setBaselineSpendingCount(i);

      i++;
    }
    setBaseLine(baseLine);

    if (form.staticBaseLine && form.staticBaseLine.length == 0) {
      // Copy baseLine as staticBaseLine
      setStaticBaseLine((form.staticBaseLine = baseLine));
    } else if (form.staticBaseLine && form.staticBaseLine.length) {
      // Apply loaded staticBaseLine
      setStaticBaseLine(form.staticBaseLine);
    }
  };

  const getCurrentSpending = () => {
    var i = 0;
    var currentSpendingValue = [];
    while (countMonths >= i) {
      var totalMoneyInPerMonth = getTotalMoneyIn() * i;
      var moneyAvailable = getTotalMoneyAvailable();
      var expensesPerMonth = getTotalMoneyOut() * i;
      const spendingValue =
        totalMoneyInPerMonth + moneyAvailable - expensesPerMonth;

      if (spendingValue > 0) currentSpendingValue.push(spendingValue);
      else break;
      setCurrentSpendingCount(i);

      i++;
    }
    setCurrentSpending(currentSpendingValue);
  };

  const getEssentialSpending = () => {
    var i = 0;
    var currentEssentialSpendingValue = [];

    while (countMonths >= i) {
      var totalMoneyInPerMonth = getTotalMoneyIn() * i;
      var moneyAvailable = getTotalMoneyAvailable();
      var expensesPerMonth = getTotalMoneyOutEssential() * i;

      const essentialSpendingValue =
        totalMoneyInPerMonth + moneyAvailable - expensesPerMonth;

      if (essentialSpendingValue > 0)
        currentEssentialSpendingValue.push(essentialSpendingValue);
      else break;

      setEssentialSpendingCount(i);

      i++;
    }
    setEssentialSpending(currentEssentialSpendingValue);
  };

  const getMonthList = () => {
    var i = 0;
    var months = [];

    while (countMonths > i) {
      var allTotalMoneyInPerMonth = getAllTotalMoneyIn() * i;
      var moneyAvailable = getTotalMoneyAvailable();
      var expensesPerMonth = getTotalMoneyOut() * i;

      const baseLineValue =
        allTotalMoneyInPerMonth + moneyAvailable - expensesPerMonth;

      if (baseLineValue > 0) months.push(i);
      else break;

      i++;
    }
    setMonthsLabel(months);
  };

  return (
    <div className={styles.graphStretchRoot}>
      <div className={styles.infoQuestionWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={styles.importLiveDataButton}
          onClick={handleImportLiveData}
        >
          IMPORT LIVE DATA
        </Button>
        <div className={styles.questionAnswerWrapper}>
          <QuestionMarkButton
            cssClass={styles.questionIconGraph}
            onClick={() => setShowInforMessage(!showInfoMessage)}
          />
          {showInfoMessage ? (
            <div className={clsx(Fstyles.messageBlock, Fstyles.questionAbove)}>
              <div>
                <Typography
                  color="primary"
                  variant="h6"
                  className={styles.paragraphTitle}
                >
                  <b>Import Live Data from Portal</b>
                </Typography>
                <Typography variant="h6" className={styles.paragraphTitle}>
                  <p>
                    Please note that the data you import and play with in
                    MoneySTRETCH <br /> does not automatically change your live
                    data in moorr.
                    <br />
                    <br />
                    If you do wish to update your live data with
                    <br />
                    changes you make to the expenses data, you can publish this
                    <br />
                    back into moorr to become your new live data, <br />
                    using the Publish Changes button located within the expenses{" "}
                    <br />
                    section on this page.
                  </p>
                </Typography>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Typography variant="subtitle2" className={styles.fadedParagraph}>
        Please note that other than expense changes you have chosen to publish,
        changes made on this page do not update to other areas of moorr.
      </Typography>
      <div className={styles.graphContainer}>
        <Line
          legend={{ display: false }}
          data={{
            datasets: [
              {
                label: "Baseline",
                data: baseLine,
                backgroundColor: "transparent",
                fill: false,
                borderColor: "#FF1010",
                pointBorderColor: "#FF1010",
                pointBackgroundColor: "#FF1010",
                pointHoverBackgroundColor: "#FF1010",
                pointHoverBorderColor: "#FF1010",
                pointBorderWidth: 2,
                pointHoverRadius: 2,
                pointHoverBorderWidth: 2,
                pointRadius: 2,
              },
              {
                label: "No Change",
                data: staticBaseLine,
                backgroundColor: "transparent",
                fill: false,
                borderColor: "#000000",
                pointBorderColor: "#000000",
                pointBackgroundColor: "#000000",
                pointHoverBackgroundColor: "#000000",
                pointHoverBorderColor: "#000000",
                pointBorderWidth: 2,
                pointHoverRadius: 2,
                pointHoverBorderWidth: 2,
                pointRadius: 2,
              },
              {
                label: "Money Available with Current Spending",
                data: currentSpending,
                backgroundColor: "transparent",
                fill: false,
                borderColor: "#f79646",
                pointBorderColor: "#f79646",
                pointBackgroundColor: "#f79646",
                pointHoverBackgroundColor: "#f79646",
                pointHoverBorderColor: "#f79646",
                pointBorderWidth: 2,
                pointHoverRadius: 2,
                pointHoverBorderWidth: 2,
                pointRadius: 2,
              },
              {
                label: "Money Available with Essential Spending",
                data: essentialSpending,
                backgroundColor: "transparent",
                fill: false,
                borderColor: "#92d050",
                pointBorderColor: "#92d050",
                pointBackgroundColor: "#92d050",
                pointHoverBackgroundColor: "#92d050",
                pointHoverBorderColor: "#92d050",
                pointBorderWidth: 2,
                pointHoverRadius: 2,
                pointHoverBorderWidth: 2,
                pointRadius: 2,
                borderDash: [8, 5],
              },
            ],
            labels: monthsLabel,
          }}
          options={{
            tooltips: {
              callbacks: {
                label: function (tooltipItem: any, data: any) {
                  var label =
                    data.datasets[tooltipItem.datasetIndex].label || "";

                  if (label) {
                    label += ": ";
                  }
                  const wholeLabel = Math.ceil(tooltipItem.yLabel);
                  const truncated = Math.trunc(wholeLabel);
                  let currency = formatCurrency(truncated);
                  currency = currency.substring(0, currency.lastIndexOf("."));
                  label += currency;
                  return label;
                },
              },
            },
            responsive: true,
            maintainAspectRatio: true,
            scales: {
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Money Available",
                    fontFamily: "Poppins",
                    fontSize: 20,
                  },
                  ticks: {
                    fontColor: "#000000",
                    fontStyle: "bold",
                    beginAtZero: false,
                    min: 0,
                    callback: function (value) {
                      let currency = formatCurrency(value);
                      currency = currency.substring(
                        0,
                        currency.lastIndexOf(".")
                      );
                      return currency + " ";
                    },
                  },
                  gridLines: {
                    drawTicks: false,
                    display: true,
                    drawBorder: true,
                  },
                },
              ],
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: "Time (Months)",
                    fontSize: 20,
                    fontFamily: "Poppins",
                  },
                  ticks: {
                    padding: 20,
                    fontColor: "#000000",
                    fontStyle: "bold",
                  },
                  gridLines: {
                    drawTicks: false,
                    display: false,
                    drawBorder: false,
                  },
                },
              ],
            },
          }}
        />

        <div className={styles.legendContainerOuter}>
          <div className={styles.legendContainer}>
            <div className={styles.flexContainer}>
              <div className={styles.baseLineContainer} />
              <Typography
                display="block"
                variant="subtitle1"
                className={styles.graphParagraph}
              >
                Baseline Money Available
              </Typography>
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.spendingContainer} />
              <Typography
                display="block"
                variant="subtitle1"
                className={styles.graphParagraph}
              >
                Money Available with Current Spending
              </Typography>
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.essentialContainer} />
              <Typography
                display="block"
                variant="subtitle1"
                className={styles.graphParagraph}
              >
                Money Available with Essential Spending
              </Typography>
            </div>
            <div className={styles.flexContainer}>
              <div className={styles.noChangeContainer} />
              <Typography
                display="block"
                variant="subtitle1"
                className={styles.graphParagraph}
              >
                No Change
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphStretch;
